import Post from "./post";
import Pagination from "./pagination";
import image1 from "../../images/documentation-1.png";
import image2 from "../../images/documentation-2.png";
import image3 from "../../images/documentation-3.png";
import image4 from "../../images/documentation-4.png";
import image5 from "../../images/documentation-5.png";
import image6 from "../../images/documentation-6.png";
import image7 from "../../images/documentation-7.png";
import { Fade } from "react-awesome-reveal";

const Documentation = () => {
  const posts = [
    {
      image: image1,
      label: "1st Place DCMD Investment Paper",
    },
    {
      image: image2,
      label: "1st Place PLN Business Paper",
    },
    {
      image: image3,
      label: "2nd Winner of Capital Market Week",
    },
    {
      image: image4,
      label: "1st Place Business Plan Diponegoro Entrepreneur",
    },
    {
      image: image5,
      label: "3rd Runner Up of TRISAKTI Stock Analysis",
    },
    {
      image: image6,
      label: "3rd Place of Manifest Stock Analysis",
    },
    {
      image: image7,
      label: "Top 6th of ICMSS",
    },
  ];

  return (
    <div className="bg-achievement">
      <Fade direction="down">
        <div className="header-documentation">
          <h1 className="text-white">Our Achievement</h1>
        </div>
      </Fade>
      <Fade direction="down">
        <Pagination
          data={posts}
          RenderComponent={Post}
          title="Posts"
          pageLimit={2}
          dataLimit={6}
        />
      </Fade>
    </div>
  );
};

export default Documentation;
