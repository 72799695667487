import React from "react"
import AppRoutes from "./routes";
import './styles/index.css'
import './styles/navbar.css'
import './styles/contact-us.css'
import './styles/footer.css'
import './styles/podcast.css'
import './styles/home.css'
import './styles/documentation.css'
import './styles/education.css'
import './styles/profile.css'
import './styles/events.css'
import './styles/tradingview.css'

function App() {
  return (
    <AppRoutes />
  );
}

export default App;

