import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "./home";
import Profile from "./profile";
import Education from "./education";
import Event from "./event";
import Podcast from "./podcast";
import Documentation from "./documentation";
import ContactUs from "./contact-us";
// import Pendaftaran from "./pendaftaran";
import Product from "../components/Education/education-product";
import Footer from "../components/Footer/index";
import MarketRecap from "../components/Education/Content/marketRecap";
import Highlight from "../components/Education/Content/highlight";
import Tradingview from "./tradingview";

class AppRoutes extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route exact path="/education" element={<Education />} />
          <Route
            path="/education/marketrecap/:id"
            element={<Product data={MarketRecap} />}
          />
          <Route
            path="/education/highlight/:id"
            element={<Product data={Highlight} />}
          />
          <Route path="/events" element={<Event />} />
          <Route path="/podcast" element={<Podcast />} />
          <Route path="/documentation" element={<Documentation />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/tradingview" element={<Tradingview />} />
          {/* <Route path="/pendaftaran" element={<Pendaftaran />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    );
  }
}

export default AppRoutes;
