import NavBar from "../components/NavBar/index";
import Header from "../components/Home/header";
import Main from "../components/Home/index";
import {useEffect} from 'react';

const Home = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return (
        <div>
            <NavBar />
            <Header />
            <Main />
        </div>
    )
}

export default Home;