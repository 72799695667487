import pdf1 from "../weekly/weekly13juni22.pdf";
import pdf2 from "../weekly/weekly20juni22.pdf";
import pdf3 from "../weekly/weekly27juni22.pdf";
import pdf4 from "../weekly/weekly4juli22.pdf";
import pdf5 from "../weekly/weekly11juli22.pdf";
import pdf6 from "../weekly/weekly18juli22.pdf";
import pdf7 from "../weekly/weekly25juli22.pdf";
import pdf8 from "../weekly/weekly1agustus22.pdf";
import pdf9 from "../weekly/weekly8agustus22.pdf";
import pdf10 from "../weekly/weekly15agustus22.pdf";
import pdf11 from "../weekly/weekly22agustus22.pdf";
import pdf12 from "../weekly/weekly29agustus22.pdf";
import pdf13 from "../weekly/weekly5september22.pdf";
import pdf14 from "../weekly/weekly12september22.pdf";
import pdf15 from "../weekly/weekly19september22.pdf";
import pdf16 from "../weekly/weekly26september22.pdf";
import pdf17 from "../weekly/weekly10oktober22.pdf";
import pdf18 from "../weekly/weekly17oktober22.pdf";
import pdf19 from "../weekly/weekly24oktober22.pdf";
import pdf20 from "../weekly/weekly31oktober22.pdf";
import pdf21 from "../weekly/weekly7november22.pdf";
import pdf22 from "../weekly/weekly14november22.pdf";
import pdf23 from "../weekly/weekly21november22.pdf";
import pdf24 from "../weekly/weekly28november22.pdf";
import pdf25 from "../weekly/weekly26desember22.pdf";
import pdf26 from "../weekly/weekly2januari23.pdf";
import pdf27 from "../weekly/weekly9januari23.pdf";
import pdf28 from "../weekly/weekly16januari23.pdf";
import pdf29 from "../weekly/weekly23januari23.pdf";
import pdf30 from "../weekly/weekly30januari23.pdf";
import pdf31 from "../weekly/weekly6februari23.pdf";
import pdf32 from "../weekly/weekly13februari23.pdf";
import pdf33 from "../weekly/weekly27maret23.pdf";
import pdf34 from "../weekly/weekly3april23.pdf";
import pdf35 from "../weekly/weekly10april23.pdf";
import pdf36 from "../weekly/weekly17april23.pdf";
import pdf37 from "../weekly/weekly26april23.pdf";
import pdf38 from "../weekly/weekly8mei23.pdf";
import pdf39 from "../weekly/weekly15mei23.pdf";
import pdf40 from "../weekly/weekly22mei23.pdf";
import pdf41 from "../weekly/weekly29mei23.pdf";
import pdf42 from "../weekly/weekly5juni23.pdf";
import pdf43 from "../weekly/weekly12juni23.pdf";


const pageMR = {
  title: "Weekly Market Recap",
  subtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras turpis quam, tincidunt id nibh vel, viverra feugiat arcu. Pellentesque eget ornare eros, et sagittis dui. Phasellus a urna et nulla suscipit sagittis sed sed sapien.",
  data: [
    {
      id: "43",
      title: "Weekly Market Recap",
      subtitle: "12 Juni 2023 - 16 Juni 2023",
      pdf: pdf43,
      image: "./image/marketrecap/weekly12juni23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait PTBA Resmi Bagi Dividen Rp 12,6 Triliun, Harga minyak Perpanjang Kenaikan, Ditopang Membaiknya Prospek Permintaan Tiongkok, dan The Fed Tahan Kenaikan Suku Bunga Acuan.",
    },
    {
      id: "42",
      title: "Weekly Market Recap",
      subtitle: "5 Juni 2023 - 9 Juni 2023",
      pdf: pdf42,
      image: "./image/marketrecap/weekly5juni23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Jelang Bagi Dividen, Saham PTBA Melesat 10,46% dalam Sepekan, Patrick Walujo Pimpin GOTO, Analis Sebut Kepercayaan Investor Meningkat, dan Moratelindo Rilis Sukuk Berkelanjutan Rp 3 Triliun.",
    },
    {
      id: "41",
      title: "Weekly Market Recap",
      subtitle: "29 Mei 2023 - 1 Juni 2023",
      pdf: pdf41,
      image: "./image/marketrecap/weekly29mei23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Data Tenaga Kerja Kuat, Wall Street Dibuka 'Pesta Pora', Minyak Terkerek di Atas 2%, dan Saham GOTO Tiba-tiba Ditutup ARA.",
    },
    {
      id: "40",
      title: "Weekly Market Recap",
      subtitle: "22 Mei 2023 - 26 Mei 2023",
      pdf: pdf40,
      image: "./image/marketrecap/weekly22mei23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Siloam Genggam Capex Rp1,4 T Siap Bangun RS Tiap Tahun, Akhirnya Bagikan Dividen, Mulia Industrindo (MLIA) Tetapkan Rp 172 Miliar, dan Menteri ESDM Beberkan Rencana Merah Putihkan Vale.",
    },
    {
      id: "39",
      title: "Weekly Market Recap",
      subtitle: "15 Mei 2023 - 19 Mei 2023",
      pdf: pdf39,
      image: "./image/marketrecap/weekly15mei23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Laba Harita Nickel Melesat 137%, PGEO siap terbitkan Green Bond US$ 500 Juta, dan MIDI Gencar Ekspansi Gerai Tahun ini.",
    },
    {
      id: "38",
      title: "Weekly Market Recap",
      subtitle: "8 Mei 2023 - 12 Mei 2023",
      pdf: pdf38,
      image: "./image/marketrecap/weekly8mei23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Indeks Wall Street, Dow Jones Turun 0,03%, S&P 500 Melorot 0,16%, IHSG Turun 1,18% Sepekan, Terimbas Pasar Saham Global, dan Nasdaq Berpeluang Naik Jelang data Prelim UoM Consumer Sentiment.",
    },
    {
      id: "37",
      title: "Weekly Market Recap",
      subtitle: "26 April 2023 - 28 April 2023",
      pdf: pdf37,
      image: "./image/marketrecap/weekly26april23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Wall Street Berakhir Hijau Akibat Lonjakan Laba Emiten, IHSG Diperkirakan Akan Menembus 7000 Bulan Depan, dan Harga Minyak Dunia Turun Meskipun Saham Wall Street Menguat.",
    },
    {
      id: "36",
      title: "Weekly Market Recap",
      subtitle: "17 April 2023 - 18 April 2023",
      pdf: pdf36,
      image: "./image/marketrecap/weekly17april23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG dan juga beberapa Highlight terkait Kekhawatiran Resesi Mengakibatkan Saham Asia Mengalami Penurunan, Dunia Diprediksi Kekurangan Pasokan Beras Terbesar dalam 20 Tahun, dan Wall Street Merosot Akibat Laporan Pendapatan yang Suram.",
    },
    {
      id: "35",
      title: "Weekly Market Recap",
      subtitle: "10 April 2023 - 14 April 2023",
      pdf: pdf35,
      image: "./image/marketrecap/weekly10april23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG, Chart of the week yang menarik, dan juga beberapa Highlight terkait Harga Emas turun 2% Akibat Sinyal Terbaru The Fed, Bursa Wall Street Memerah di Akhir Pekan Ini, dan Antrean IPO Perusahaan Melantai di Bursa Cukup Tinggi.",
    },
    {
      id: "34",
      title: "Weekly Market Recap",
      subtitle: "3 April 2023 - 7 April 2023",
      pdf: pdf34,
      image: "./image/marketrecap/weekly3april23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG, Chart of the week yang menarik, dan juga beberapa Highlight terkait Nonfarm Payrolls Maret mencapai 236.000, Harga Emas Berhasil Menembus $2000, dan Kondisi Wall Street yang Tertekan Menjelang Libur Panjang.",
    },
    {
      id: "33",
      title: "Weekly Market Recap",
      subtitle: "27 Maret 2023 - 31 Maret 2023",
      pdf: pdf33,
      image: "./image/marketrecap/weekly27maret23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait Recap IHSG, Chart of the week yang menarik, dan juga beberapa Highlight terkait Kepulan Asap Positif Emiten Rokok, Aksi BUMI akan segera Akuisisi dan Diversifikasi, dan Laba Saham BIRD yang moncer hingga 4545 %.",
    },
    {
      id: "32",
      title: "Weekly Market Recap",
      subtitle: "13 Februari 2023 - 17 Februari 2023",
      pdf: pdf32,
      image: "./image/marketrecap/weekly13februari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Target Penjualan ADRO dan Suntikan Dana untuk TOWR",
    },
    {
      id: "31",
      title: "Weekly Market Recap",
      subtitle: "6 Februari 2023 - 10 Februari 2023",
      pdf: pdf31,
      image: "./image/marketrecap/weekly6februari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Pemangkasan Produksi Minyak oleh Rusia dan Peluang Akuisisi Lapangan Migas oleh MEDC.",
    },
    {
      id: "30",
      title: "Weekly Market Recap",
      subtitle: "30 Januari 2023 - 3 Februari 2023",
      pdf: pdf30,
      image: "./image/marketrecap/weekly30januari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Buyback BBRI dan Saham GOTO",
    },
    {
      id: "29",
      title: "Weekly Market Recap",
      subtitle: "23 Januari 2023 - 27 Januari 2023",
      pdf: pdf29,
      image: "./image/marketrecap/weekly23januari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Kenaikan BI-7 Days Repo Rate dan Pengembangan bisnis SIDO.",
    },
    {
      id: "28",
      title: "Weekly Market Recap",
      subtitle: "16 Januari 2023 - 20 Januari 2023",
      pdf: pdf28,
      image: "./image/marketrecap/weekly16januari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait kenaikan BI7DRRR dan pengembangan Ekspor oleh SIDO.",
    },
    {
      id: "27",
      title: "Weekly Market Recap",
      subtitle: "9 Januari 2023 - 13 Januari 2023",
      pdf: pdf27,
      image: "./image/marketrecap/weekly9januari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Right Issue oleh BSWD dan penawaran obligasi serta sukuk oleh INKP",
    },
    {
      id: "26",
      title: "Weekly Market Recap",
      subtitle: "2 Januari 2022 - 6 Januari 2022",
      pdf: pdf26,
      image: "./image/marketrecap/weekly2januari23.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Buyback saham IFSH dan TPIA akuisisi anak perusahaan KRAS",
    },
    {
    id: "25",
    title: "Weekly Market Recap",
    subtitle: "26 Desember 2022 - 30 Desember 2022",
    pdf: pdf25,
    image: "./image/marketrecap/weekly26desember22.jpg",
    text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Kenaikan saham BYAN dan proyek terbaru WSKT.",
    },
    {
    id: "24",
    title: "Weekly Market Recap",
    subtitle: "28 November 2022 - 2 Desember 2022",
    pdf: pdf24,
    image: "./image/marketrecap/weekly28november22.jpg",
    text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Pergerakan Harga GOTO dan Sukuk Bunga BMRI",
    },
    {
      id: "23",
      title: "Weekly Market Recap",
      subtitle: "21 November 2022 - 25 November 2022",
      pdf: pdf23,
      image: "./image/marketrecap/weekly21november22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait right issue oleh VICO dan kenaikan laba BSSR.",
    },
    {
      id: "22",
      title: "Weekly Market Recap",
      subtitle: "14 November 2022 - 18 November 2022",
      pdf: pdf22,
      image: "./image/marketrecap/weekly14november22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait kenaikan BI7DRR dan PHK massal GOTO",
    },
    {
      id: "21",
      title: "Weekly Market Recap",
      subtitle: "07 November 2022 - 11 November 2022",
      pdf: pdf21,
      image: "./image/marketrecap/weekly7november22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait ADHI mendapatkan dana hasil right issue dan BSBK yang melakukan ekspansi di IKN.",
    },
    {
      id: "20",
      title: "Weekly Market Recap",
      subtitle: "31 Oktober 2022 - 04 November 2022",
      pdf: pdf20,
      image: "./image/marketrecap/weekly31oktober22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait pendapatan Barito Pacific pada Kuartal III 2022 serta Kenaikan Laba Metropolitan Land (MTLA) sebesar 32% pada Kuartal III 2022.",
    },
    {
      id: "19",
      title: "Weekly Market Recap",
      subtitle: "24 Oktober 2022 - 28 Oktober 2022",
      pdf: pdf19,
      image: "./image/marketrecap/weekly24oktober22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait rekor laba bersih dari PTBA dan kenaikan laba dari BBNI.",
    },
    {
      id: "18",
      title: "Weekly Market Recap",
      subtitle: "17 Oktober 2022 - 21 Oktober 2022",
      pdf: pdf18,
      image: "./image/marketrecap/weekly17oktober22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Kenaikan BI 7 Day Reverse Repo Date dan Penerbitan Obligasi senilai Rp 1 Trilyun oleh TBIG.",
    },
    {
      id: "17",
      title: "Weekly Market Recap",
      subtitle: "10 Oktober 2022 - 14 Oktober 2022",
      pdf: pdf17,
      image: "./image/marketrecap/weekly10oktober22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Pembelian saham BUMI oleh Salim Group dan Pelemahan kurs Rupiah.",
    },
    {
      id: "16",
      title: "Weekly Market Recap",
      subtitle: "26 September 2022 - 30 September 2022",
      pdf: pdf16,
      image: "./image/marketrecap/weekly26september22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Pendapatan KAEF dan portofolio INDY",
    },
    {
      id: "15",
      title: "Weekly Market Recap",
      subtitle: "19 September 2022 - 23 September 2022",
      pdf: pdf15,
      image: "./image/marketrecap/weekly19september22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Astra yang memborong saham HEAL dan BSI menargetkan kenaikan pembiayaan.",
    },
    {
      id: "14",
      title: "Weekly Market Recap",
      subtitle: "12 September 2022 - 16 September 2022",
      pdf: pdf14,
      image: "./image/marketrecap/weekly12september22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Penjualan SBN oleh BCA dan Buyback saham oleh Adaro Energy.",
    },
    {
      id: "13",
      title: "Weekly Market Recap",
      subtitle: "5 September 2022 - 9 September 2022",
      pdf: pdf13,
      image: "./image/marketrecap/weekly5september22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Right Issue BTN dan Pembelian kembali saham SMDR oleh perusahaan",
    },
    {
      id: "12",
      title: "Weekly Market Recap",
      subtitle: "29 Agustus 2022 - 2 September 2022",
      pdf: pdf12,
      image: "./image/marketrecap/weekly29agustus22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Kenaikan BBM subsidi dan PHK massal startup",
    },
    {
      id: "11",
      title: "Weekly Market Recap",
      subtitle: "22 Agustus 2022 - 26 Agustus 2022",
      pdf: pdf11,
      image: "./image/marketrecap/weekly22agustus22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Kenaikan suku bunga BI, Ekspansi ACES, dan lainnya",
    },
    {
      id: "10",
      title: "Weekly Market Recap",
      subtitle: "15 Agustus 2022 - 19 Agustus 2022",
      pdf: pdf10,
      image: "./image/marketrecap/weekly15agustus22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Right Issue ADRO dan BRIS, Portofolio LKH, dan Inflasi Inggris.",
    },
    {
      id: "9",
      title: "Weekly Market Recap",
      subtitle: "8 Agustus 2022 - 12 Agustus 2022",
      pdf: pdf9,
      image: "./image/marketrecap/weekly8agustus22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Kenaikan tarif Ojek Online, penjualan aset MRAT, dan peluncuran produk baru INDY.",
    },
    {
      id: "8",
      title: "Weekly Market Recap",
      subtitle: "1 Agustus 2022 - 5 Agustus 2022",
      pdf: pdf8,
      image: "./image/marketrecap/weekly1agustus22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Prestasi MTEL, Pembelian alokasi dana BIPI, Pergerakan dari Salim Group serta lonjakan laba ASII.",
    },
    {
      id: "7",
      title: "Weekly Market Recap",
      subtitle: "25 Juli 2022 - 29 Juli 2022",
      pdf: pdf7,
      image: "./image/marketrecap/weekly25juli22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Peningkatan laba IMPC, Peningkatan penggunaan jasa JSMR, dan rekor baru pendapatan ESSA.",
    },
    {
      id: "6",
      title: "Weekly Market Recap",
      subtitle: "18 Juli 2022 - 22 Juli 2022",
      pdf: pdf6,
      image: "./image/marketrecap/weekly18juli22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Mega Proyek Data FREN dan Prestasi BBRI di tahun ini.",
    },
    {
      id: "5",
      title: "Weekly Market Recap",
      subtitle: "11 Juli 2022 - 15 Juli 2022",
      pdf: pdf5,
      image: "./image/marketrecap/weekly11juli22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Stock split MLIA dan Joint Venture antara TLKM dengan GOTO.",
    },
    {
      id: "4",
      title: "Weekly Market Recap",
      subtitle: "4 Juli 2022 - 8 Juli 2022",
      pdf: pdf4,
      image: "./image/marketrecap/weekly4juli22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait SMRA bagikan Dividen, Kinerja NICL yang positif serta penataan ulang BIPI.",
    },
    {
      id: "3",
      title: "Weekly Market Recap",
      subtitle: "27 Juni 2022 - 1 Juli 2022",
      pdf: pdf3,
      image: "./image/marketrecap/weekly27juni22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Nama baru Mahaka Media(ABBA) dan Penerbitan Green Bond BBRI.",
    },
    {
      id: "2",
      title: "Weekly Market Recap",
      subtitle: "20 Juni 2022 - 24 Juni 2022",
      pdf: pdf2,
      image: "./image/marketrecap/weekly20juni22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Unilever (UNVR) menguat sebagai akibat dari pembagian dividen dan Bukalapak (BUKA) gelar RUPST.",
    },
    {
      id: "1",
      title: "Weekly Market Recap",
      subtitle: "13 Juni 2022 - 17 Juni 2022",
      pdf: pdf1,
      image: "./image/marketrecap/weekly13juni22.jpg",
      text: "Pada minggu ini, divisi Research & Curriculum Development kembali menyediakan Weekly Market Recap yang memberitakan berbagai informasi terkait forecast IHSG, Global Summary, dan juga beberapa Highlight terkait Harga emas ANTAM kian meningkat dan peluang menyebarnya bisnis PT Wintermar (WINS).",
    },
  ],
};

export default pageMR;
