import Sport from "../../images/SPORTSDAY.jpg";
import Syukwis from "../../images/syukwis.jpg";
import Dies from "../../images/dies.jpg";
import BEI from "../../images/BEI.jpg";
import Intra from "../../images/event-2.png";
import CompanyVisit from "../../images/CompanyVisit.jpg";
import PTL from "../../images/PTL.jpg";
import Infest from "../../images/INFEST.png";
import OHU from "../../images/OHU.jpg";
import Pemilu from "../../images/Pemilu.jpg";
import Day from "../../images/DAY.jpg";
import HTT from "../../images/HTT.jpg";
import KSPM from "../../images/KSPM.jpg";
import MarketReview from "../../images/market-review.jpg";
import Traction from "../../images/TRACTION.png";
import Incubation from "../../images/INCUBATION.jpg";

const DataEvent = [
  {
    divisi: "External",
    konten: [
      {
        id: 1,
        title: "KSEP Goes to BEI",
        paragraph:
          "Melakukan kunjungan ke BEI secara offline ataupun online untuk menambah wawasan dan pengetahuan massa KSEP tentang BEI.",
        image: BEI,
      },
      {
        id: 2,
        title: "Kolaborasi Intrakampus",
        paragraph:
          "Membangun relasi dengan pihak intrakampus ITB dengan mengadakan kolaborasi mutualisme dari KSEP dengan pihak intrakampus.",
        image: Intra,
      },
      {
        id: 3,
        title: "Kolaborasi dengan KSPM Universitas Lain",
        paragraph:
          "Membangun relasi dengan organisasi sejenis KSEP di universitas lain dalam bentuk kolaborasi sharing insight dan kerja sama project.",
        image: KSPM,
      },
      {
        id: 4,
        title: "Company Visit",
        paragraph:
          "Melakukan kunjungan ke company secara offline ataupun online.",
        image: CompanyVisit,
      },
    ],
  },
  {
    divisi: "Internal",
    konten: [
      {
        id: 1,
        title: "KSEP HTT",
        paragraph:
          "Merangkai acara dengan beragam hobi tertentu yang digemari oleh massa KSEP, contoh di antaranya jalan bersama (tour), main game (PS) bersama, masak, menonton bersama, dan sebagainya sesuai dengan hobi para massa KSEP.",
        image: HTT,
      },
      {
        id: 2,
        title: "KSEP Day",
        paragraph:
          "Meningkatkan rasa kekeluargaan antar anggota KSEP melalui rangkaian acara yang disusun.",
        image: Day,
      },
      {
        id: 3,
        title: "KSEP Sport Day",
        paragraph:
          "Meningkatkan kesehatan dan kebersamaan antar sesama massa KSEP melalui acara yang melibatkan olahraga.",
        image: Sport,
      },
      {
        id: 4,
        title: "Syukuran Wisuda",
        paragraph:
          "Mengapresiasi Massa KSEP yang telah menyelesaikan studi di ITB.",
        image: Syukwis,
      },
      {
        id: 5,
        title: "Dies Natalis",
        paragraph: "Memeriahkan peringatan hari ulang tahun KSEP.",
        image: Dies,
      },
    ],
  },
  {
    divisi: "Research & Curriculum Development",
    konten: [
      {
        id: 1,
        title: "PTD dan PTL",
        paragraph:
          "Memberikan wadah bagi massa KSEP untuk melakukan proses belajar-mengajar dan wadah pengembangan diri bagi massa KSEP.",
        image: PTL,
      },
      {
        id: 2,
        title: "Market Review",
        paragraph:
          "Memberikan edukasi tentang kondisi terkini ekonomi dan pasar modal kepada massa KSEP dalam bentuk webinar.",
        image: MarketReview,
      },
    ],
  },
  {
    divisi: "Human Resource Development",
    konten: [
      {
        id: 1,
        title: "KSEP Traction",
        paragraph:
          "Meningkatkan kualitas SDM massa KSEP, baik dari softskill maupun hardskill.",
        image: Traction,
      },
      {
        id: 2,
        title: "KSEP Incubation",
        paragraph:
          "Memberikan wadah bagi massa untuk mendapatkan sesi tutoring/mentor ketika mengikuti lomba dalam hal ekonomi, pasar modal dan investasi.",
        image: Incubation,
      },
    ],
  },
  {
    divisi: "Project Supervisor",
    konten: [
      {
        id: 1,
        title: "INFEST",
        paragraph:
          "Wadah praktik pengabdian pada masyarakat dengan tujuan untuk meningkatkan literasi finansial masyarakat.",
        image: Infest,
      },
      {
        id: 2,
        title: "OHU dan Regenerasi",
        paragraph:
          "Menjaga eksistensi KSEP sebagai unit ekonomi dan pasar modal di ITB dengan melanjutkan membentuk angkatan baru melalui kaderisasi dengan nilai-nilai kaderisasi di KSEP.",
        image: OHU,
      },
      {
        id: 3,
        title: "Pemilu",
        paragraph:
          "Menjaga keberlanjutan kepengurusan KSEP ITB dengan melanjutkan tongkat estafet badan pengurus KSEP di KSEP ITB.",
        image: Pemilu,
      },
    ],
  },
];

export default DataEvent;
