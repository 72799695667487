import Button from "./button";
const Intro = (props) => {
  if (props.align === "left") {
    return (
      <div className="intro-template md:p-[4rem] pt-[4rem] text-justify">
        <h2 className="text-center md:text-left">{props.title}</h2>
        <p>{props.content}</p>
        <span className="mx-auto md:m-0">
          <Button nav={props.nav} />
        </span>
      </div>
    );
  } else {
    return (
      <div className="intro-template intro-right md:p-[4rem]">
        <h2>{props.title}</h2>
        <p>{props.content}</p>
        <span>
          <Button nav={props.nav} />
        </span>
      </div>
    );
  }
};

export default Intro;
