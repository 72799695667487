import NavBar from "../components/NavBar/index";
import Header from "../components/KSEPodcast/header";
import Main from "../components/KSEPodcast/index";
import { useEffect } from "react";

const Podcast = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-podcast">
      <NavBar />
      <Header />
      <Main />
    </div>
  );
};

export default Podcast;
