const Header = (props) => {
  return (
    <div className="header-education z-[2] relative">
      <div className="sm:w-1/3 sm:ml-[5%] flex flex-col font-bold lg:pt-[10%] pt-[20%]">
        <span className="text-[80px] sm:text-[96px] sm:tracking-[2rem] text-[#C81D39] leading-[6rem] sm:w-full mx-auto">
          KSEP
        </span>
        <span className="text-[80px] sm:text-[96px] text-[#00405A] leading-[6rem] mx-auto sm:w-full mx-auto">
          Education
        </span>
        <div className="flex flex-col gap-4 sm:w-[25rem] text-4xl font-bold mt-10 sm:mx-0 mx-auto">
          <button
            onClick={props.button1}
            className={`p-4 ${
              props.page
                ? "bg-[#00405A] text-white"
                : "bg-[#F3EFE7] text-[#C81D39]"
            }`}
          >
            KSEP HIGHLIGHTS
          </button>
          <button
            onClick={props.button2}
            className={`p-4 ${
              props.page
                ? "bg-[#F3EFE7] text-[#C81D39]"
                : "bg-[#00405A] text-white"
            }`}
          >
            MARKET RECAP
          </button>
        </div>
      </div>
      <div className="absolute w-[500px] h-[500px] left-[-124px] bg-red-linear rounded-[50%] top-[45%] overflow-hidden z-[-1]"></div>
    </div>
  );
};

export default Header;
