import downArrow from "../../images/down-arrow.png";
import upArrow from "../../images/up-arrow.png";
import React from "react";
import { Fade } from "react-awesome-reveal";
class PodcastList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      List: [
        {
          id: "00",
          title: "EP 0: Kenalan sama KSEPodcast",
          src: "https://open.spotify.com/embed/episode/74vFVhtSljs5g3yfhwTE9M?utm_source=generator&t=0",
          active: true,
        },
        {
          id: "01",
          title: "EP 1: Hal-hal yang Harus Diketahui Sebelum Berinvestasi",
          src: "https://open.spotify.com/embed/episode/6MGNJ5m3WIcGrrVyUmXd2o?utm_source=generator&t=0",
          active: false,
        },
        {
          id: "02",
          title:
            'EP 2: Belajar Investasi Saham dari "Nol" bersama dengan Mirae Asset',
          src: "https://open.spotify.com/embed/episode/5HUjtn4pVjFqwuMZnoLtQN?utm_source=generator&t=0",
          active: false,
        },
        {
          id: "03",
          title: "EP 3: Equity Research Competition 101",
          src: "https://open.spotify.com/embed/episode/7rpNLj7peiW855044vyzL6?utm_source=generator&t=0",
          active: false,
        },
        {
          id: "04",
          title: "EP 4: Bear Market? Tenang Aja! Ini Tips Menghadapinya",
          src: "https://open.spotify.com/embed/episode/5uK27FDbbZrhgslccqiV10?utm_source=generator&t=0",
          active: false,
        },
        {
          id: "05",
          title: "Ep 5: Kupas Tuntas Tips Memenangkan Equity Research Competition",
          src: "https://open.spotify.com/embed/episode/5fSRjw9N08lqPzNtzH8CES?utm_source=generator&t=0",
          active: false,
        },
      ],
    };

    this.onListEventHandler = this.onListChangeHandler.bind(this);
  }

  onListChangeHandler = (newList) => {
    this.setState(() => {
      return {
        List: newList,
      };
    });
  };

  render() {
    return this.state.List.map((item) => (
      <Fade triggerOnce>
        <SpotifyItem
          search={this.props.search}
          listHandler={this.onListChangeHandler}
          id={item.id}
          key={item.id}
          title={item.title}
          active={item.active}
          list={this.state.List}
          src={item.src}
        />
      </Fade>
    ));
  }
}

const SpotifyItem = (props) => {
  const changeActive = () => {
    let data = props.list;
    let condition = props.active;
    for (let item of data) {
      if (item.id === props.id) {
        item.active = !condition;
        props.listHandler(data);
        break;
      }
    }
  };
  if (props.search === "") {
    if (props.active) {
      return (
        <div className="spotify-list-active">
          <div className="spotify-drop">
            <h2>
              # {props.id} <span>{props.title}</span>
            </h2>
            <img src={upArrow} alt="up-arrow" onClick={changeActive} />
          </div>
          <iframe
            key={props.src}
            src={props.src}
            scrolling="no"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            title="eps-0"
          />
        </div>
      );
    } else {
      return (
        <div className="spotify-list">
          <div className="spotify-drop">
            <h2>
              # {props.id} <span>{props.title}</span>
            </h2>
            <img src={downArrow} alt="down-arrow" onClick={changeActive} />
          </div>
        </div>
      );
    }
  } else {
    if (props.title.toLowerCase().includes(props.search.toLowerCase())) {
      if (props.active) {
        return (
          <div className="spotify-list-active">
            <div className="spotify-drop">
              <h2>
                # {props.id} <span>{props.title}</span>
              </h2>
              <img src={upArrow} alt="up-arrow" onClick={changeActive} />
            </div>
            <iframe
              key={props.src}
              src={props.src}
              scrolling="no"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              title="eps-0"
            />
          </div>
        );
      } else {
        return (
          <div className="spotify-list">
            <div className="spotify-drop">
              <h2>
                # {props.id} <span>{props.title}</span>
              </h2>
              <img src={downArrow} alt="down-arrow" onClick={changeActive} />
            </div>
          </div>
        );
      }
    } else {
      return null;
    }
  }
};

export default PodcastList;
