const Post = (props) => {
  return (
    <div className="post lg:w-[50%] w-full">
      <img src={props.image} alt="img" className="rounded-3xl" />
      <p className="text-white">{props.label}</p>
    </div>
  );
};

export default Post;
