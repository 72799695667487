import { Fade } from "react-awesome-reveal";
const Header = () => {
  return (
    <Fade direction="down">
      <div className="header-profile">
        <div className="md:pt-[30%] lg:pt-[20%] pb-10 text-center">
          <h1 className="text-[70px] md:text-[96px] text-white">About</h1>
          <h1 className="text-[96px] text-[#2E5E86] outlinetext tracking-widest">
            KSEP
          </h1>
        </div>
      </div>
    </Fade>
  );
};

export default Header;
