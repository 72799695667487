import React, {useEffect} from 'react';
import NavBar from "../components/NavBar/index";
import Header from "../components/Tradingview/header"
import Main from "../components/Tradingview/main"


export default function Tradingview() {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
  return (
    <div>
        <NavBar/>
        <Header />
        <Main />
    </div>
  )
}