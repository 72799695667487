import { Fade } from "react-awesome-reveal";
const Event = (props) => {
  if (props.id % 2 !== 0) {
    return (
      <Fade direction="down" duration={2000}>
        <div className="event lg:flex-row flex-col-reverse">
          <div
            className={`intro-template event-detail-left w-full lg:w-2/3 lg:ml-0 mx-auto ${
              props.divisi % 2 === 0 ? "text-white" : "text-black"
            } ${props.image === "" ? "lg:ml-auto" : ""}`}
          >
            <h2
              className={`${
                props.divisi % 2 === 0
                  ? "text-[36px] font-black text-center lg:text-left"
                  : "text-[36px] font-black text-[#C81D39] text-center lg:text-left"
              }
                  ${props.image === "" ? "lg:text-center" : ""}`}
            >
              {props.title}
            </h2>
            <p className="text-justify lg:text-left">{props.paragraph}</p>
          </div>
          <img
            src={props.image}
            alt="event"
            className={`lg:ml-auto mx-auto ${
              props.image === "" ? "hidden" : ""
            }`}
          />
        </div>
      </Fade>
    );
  } else {
    return (
      <Fade direction="down" duration={2000}>
        <div className="event lg:flex-row flex-col">
          <img
            src={props.image}
            alt="event"
            className={`mx-auto lg:mx-0 ${props.image === "" ? "hidden" : ""}`}
          />
          <div
            className={`intro-template event-detail-right w-full lg:w-2/3 lg:ml-auto mx-auto ${
              props.divisi % 2 === 0 ? "text-white" : "text-black"
            }`}
          >
            <h2
              className={`
                ${
                  props.divisi % 2 === 0
                    ? "text-[36px] font-black text-center lg:text-right"
                    : "text-[36px] font-black text-[#C81D39] text-center lg:text-right"
                } ${props.image === "" ? "lg:mx-auto" : ""}`}
            >
              {props.title}
            </h2>
            <p
              className={`lg:text-right text-justify ${
                props.image === "" ? "lg:text-justify" : ""
              }`}
            >
              {props.paragraph}
            </p>
          </div>
        </div>
      </Fade>
    );
  }
};

export default Event;
