import NavBar from "../components/NavBar/index";
import Main from "../components/Education/index";
import { useEffect } from "react";

const Education = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <NavBar />
      <Main />
    </div>
  );
};

export default Education;
