import NavBar from "../components/NavBar/index";
import Contact from "../components/ContactUs/contact";
import Location from "../components/ContactUs/location";
import { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="contact-us">
      <NavBar />
      <Contact />
      <Location />
    </div>
  );
};

export default ContactUs;
