import email from "../../images/email.png";
import whatsapp from "../../images/whatsapp.png";
import { Fade } from "react-awesome-reveal";

const Contact = () => {
  return (
    <div className="bg-contact">
      <Fade direction="down">
        <div className="flex flex-col items-center lg:w-1/2 sm:w-[100%] my-40 w-full sm:pt-0">
          <h2 className="contact-title sm:text-[42px] text-[30px]">
            Get In Touch With Us!
          </h2>
          <div className="contacts">
            <div className="contact-list sm:text-[25px] text-[20px]">
              <img src={email} alt="email" />
              <a
                target="_blank"
                rel="noreferrer"
                href="mailto:ksep_itb@km.itb.ac.id"
              >
                <h3 style={{ marginTop: "2px" }}>ksep_itb@km.itb.ac.id</h3>
              </a>
            </div>
            <div
              className="contact-list sm:text-[25px] text-[20px]"
              style={{ marginTop: "20px" }}
            >
              <img
                style={{ margin: "auto auto" }}
                src={whatsapp}
                alt="whatsapp"
              />
              <div className="contact-whatsapp">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://web.whatsapp.com/send?phone=+6287722398939&amp;text=Hi, Saya mau mengajak anda untuk berkolaborasi"
                >
                  <h3 style={{ marginTop: "2px" }}>Christopher (+6282125672266)</h3>
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://web.whatsapp.com/send?phone=+6281382518798&amp;text=Hi, Saya mau mengajak anda untuk berkolaborasi"
                >
                  <h3 style={{ marginTop: "10px" }}>Alvin (+62895396180574)</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Contact;
