import Navigation from './navigation'
import {NavLink} from 'react-router-dom'
import LogoKSEP from '../../images/Logo-KSEP.png'
import Menu from '../../images/menu.png'
import X from '../../images/drop-x.png'
import {useState} from 'react'

const NavBar = () => {
	const [drop, setDrop] = useState(false)
	const navObject = [
		{
			name: 'Profile',
			path: '/profile',
		},
		{
			name: 'Education',
			path: '/education',
		},
		{
			name: 'Events',
			path: '/events',
		},
		{
			name: 'Podcast',
			path: '/podcast',
		},
		{
			name: 'Documentation',
			path: '/documentation',
		},
		{
			name: 'Contact Us',
			path: '/contact-us',
		},
		{
			name: 'KSEP x TradingView',
			path: '/tradingview',
		},
	]
	const dropDown = () => {
		setDrop(!drop)
	}
	return (
		<div className="nav">
			<div className="nav-icon">
				<NavLink to="/">
					<img
						src={LogoKSEP}
						alt="logo-ksep"
						className="lg:ml-[40%] w-[3.5rem] pt-[7px] lg:pt-[10px] ml-[2rem]"
					/>
				</NavLink>
			</div>
			<ul className="xl:flex hidden items-center">
				{navObject.map((nav) => (
					<Navigation name={nav.name} path={nav.path} key={nav.name} />
				))}
				<a
					href="https://infestitb2024.com/"
					className="nav-link flex-1 relative text-[12px]"
					target="_blank"
					rel="noreferrer"
				>
					<li>
						<h2>INFEST</h2>
					</li>
				</a>
			</ul>
			<ul className="dropdown">
				<img
					src={Menu}
					alt="menu"
					className={`block xl:hidden w-[2em] py-[19px] ml-auto mr-[2rem] cursor-pointer ${
						drop ? 'hidden' : 'block'
					}`}
					onClick={dropDown}
				/>
				<img
					src={X}
					alt="X"
					className={`block xl:hidden w-[2em] py-[19px] ml-auto mr-[2rem] cursor-pointer ${
						drop ? 'block' : 'hidden'
					}`}
					onClick={dropDown}
				/>
				<div
					id="myDropdown"
					className={`dropdown-content ${drop ? 'block' : 'hidden'}`}
				>
					{navObject.map((nav) => (
						<Navigation name={nav.name} path={nav.path} key={nav.name} />
					))}
					<a
						href="https://infestitb2024.com/"
						className="nav-link flex-1 relative"
						target="_blank"
						rel="noreferrer"
					>
						<li>
							<h2>INFEST</h2>
						</li>
					</a>
				</div>
			</ul>
		</div>
	)
}

export default NavBar
