import TVChart from "../../images/tv-chart.png";
import KSEPxTV from "../../images/KSEPxTV.png";

export default function Main() {
  return (
    <div className="tradingview-main p-8 text-justify text-xl text-white pb-20">
      <div>
        <h1 className="text-2xl md:text-4xl xl:text-6xl font-bold text-center ">
          KSEP ITB x TradingView Educational Partner Program
        </h1>
      </div>
      <div className="text-l md:text-xl xl:text-2xl">
        <div className="flex flex-col xl:flex-row">
          <div className="p-4 md:p-8 xl:p-16 flex items-center w-full xl:w-1/2 flex-col">
            <img className="w-auto" src={TVChart} alt="TradingView Chart" />
            <p className="py-16">
              With the coherent vision, KSEP ITB is eager to partner with
              TradingView through the Educational Partner Program. Through this
              partnership, KSEP ITB and TradingView are committed to contribute
              to the global market community from the early stage of young
              people, specifically Institut Teknologi Bandung students. This
              partnership will grant students free individual TradingView
              subscriptions with a tiered rewarding system. For a better
              hands-on understanding of technical analysis, students are
              obligated to support the growth of TradingView's community through
              their trading ideas.
            </p>
          </div>
          <div className="p-4 md:p-8 xl:p-16 flex items-center w-full xl:w-1/2 flex-col">
            <p>
              TradingView is a charting platform and social network used by 30M+
              traders and investors worldwide to spot opportunities across
              global markets. For over 10 years, TradingView continuously builds
              a reliable web-based charting platform with a thriving community.
              In result, it has become the world's largest social platform for
              market participants. With "Look First / Then Leap" as its
              philosophy, TradingView believes the best way to maximize returns
              in the market is through preparing then committing.
            </p>
            <img
              className="py-16 w-full"
              src={KSEPxTV}
              alt="KSEP x TradingView"
            />
          </div>
        </div>
        <div className="px-16 md:px-24 xl:px-32">
          <p>
            {" "}
            It is truly an honor for us to strive for a better financial
            literacy in Indonesia through this partnership. So, what are you
            waiting for? Register now and be a part of our educational partner
            program!
            <br></br>
            <br></br>
          </p>
          <div className="flex justify-center">
            <a
              href="Https://bit.ly/KSEPITBxTradingView"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="tradingview-btn">Join Now</button>
            </a>
          </div>
          <p>
            <br></br>
            <a
              href="http://id.tradingview.com"
              className="underline font-semibold"
            >
              Visit
            </a>{" "}
            TradingView's website to explore more about their incredible
            platform and community.
            <br></br>
            <br></br>
            For more information (whatsapp):
          </p>
          <div className="sm:text-[25px] text-[20px] flex flex-row">
            <div>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://web.whatsapp.com/send?phone=+6282277243215&amp;text=Hi, Saya mau mengajak anda untuk berkolaborasi"
              >
                <p style={{ marginTop: "2px" }}>Christopher (+6282125672266)</p>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://web.whatsapp.com/send?phone=+6281382518798&amp;text=Hi, Saya mau mengajak anda untuk berkolaborasi"
              >
                <p style={{ marginTop: "10px" }}>Alvin (+62895396180574)</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
