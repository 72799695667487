const Button = () => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      className="m-auto md:m-0"
      href="https://open.spotify.com/show/7LBV7LvpMF0eFeu22FqgNJ?si=a9dd15ec97774cf9"
    >
      <button className="button-follow text-black">Follow Now</button>
    </a>
  );
};

export default Button;
