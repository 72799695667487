const Card = (props) => {
  let link = "";
  if (props.title === "KSEP Highlight") {
    link = "/education/highlight";
  } else {
    link = "/education/marketrecap";
  }
  return (
    <div className="card xl:w-1/3 lg:w-1/2 w-full">
      <div className="flex flex-col">
        <div className="card-thumbnail-container">
          <img className="card-thumbnail" src={props.image} alt="contoh" />
        </div>
        <div className="title-container">
          <a
            href={props.data.url}
            target="_blank"
            rel="noreferrer"
            className="title"
          >
            {props.data.title}
          </a>
          <p className="sub-title">{props.data.subtitle}</p>
        </div>
        <a href={`${link}/${props.id}`} className="m-auto">
          <button className="card-button">Read More</button>
        </a>
      </div>
    </div>
  );
};

export default Card;
