import LocationImage from "../../images/location.png";
import { Fade } from "react-awesome-reveal";

const Location = () => {
  return (
    <div className="flex flex-col relative overflow-hidden">
      <Fade triggerOnce>
        <div className="absolute lg:w-[500px] lg:h-[500px] sm:w-[400px] sm:h-[400px] w-[250px] h-[250px] left-[-124px] top-[-20%] bg-red-linear rounded-[50%] overflow-hidden"></div>
      </Fade>
      <Fade direction="down">
        <div className="location">
          <h1 className="text-5xl">Our Location</h1>
          <img
            src={LocationImage}
            alt="location"
            className="m-auto mt-4 sm:w-[35rem] w-[25rem]"
          />
          <p className="text-black">
          Jalan Dayang Sumbi, Coblong, West Java 40132, ID (ITB)
          </p>
        </div>
      </Fade>
      <Fade triggerOnce>
        <div className="absolute lg:w-[500px] lg:h-[500px] sm:w-[400px] sm:h-[400px] w-[250px] h-[250px] right-[-124px] top-[70%] sm:top-[50%] bg-blue-linear rounded-[50%] overflow-hidden"></div>
      </Fade>
    </div>
  );
};

export default Location;
