import { NavLink } from "react-router-dom";
function Navigation({ name, path }) {
  return (
    <NavLink
      to={path}
      className={`nav-link flex-1 relative ${
        name === "KSEP x TradingView" ? "text-[11px] px-0" : "text-[12px]"
      }`}
    >
      <li>
        <h2>{name}</h2>
      </li>
    </NavLink>
  );
}

export default Navigation;
