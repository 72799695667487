import Event from "./event";
const Divisi = (props) => {
  if (props.id % 2 !== 0) {
  }
  return (
    <div className={`${props.id % 2 === 0 ? "bg-divisi" : ""} relative`}>
      <div className="divisi-event py-20">
        <h1 className="text-white py-4 w-full xl:w-1/2 xl:left-[25%] lg:text-[50px]">
          {props.divisi}
        </h1>
        <div className="w-4/5 mx-auto">
          {props.konten.map((item) => (
            <Event
              id={item.id}
              divisi={props.id}
              title={item.title}
              paragraph={item.paragraph}
              image={item.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Divisi;
