import { Fade } from "react-awesome-reveal";
const Header = () => {
  return (
    <Fade direction="down">
      <div className="header-event">
        <h1>Events</h1>
      </div>
    </Fade>
  );
};

export default Header;
