import NavBar from "../components/NavBar/index";
import {useEffect} from 'react';
import Header from "../components/Profile/header";
import Main from "../components/Profile/main";

const Profile = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return (
        <div>
            <NavBar />
            <Header />
            <Main />
        </div>
    )
}

export default Profile;