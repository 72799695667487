import { NavLink } from "react-router-dom";

const Button = (props) => {
  return (
    <NavLink to={props.nav}>
      <button className="button-direct">See More</button>
    </NavLink>
  );
};

export default Button;
