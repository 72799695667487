import React from "react";
import SearchIcon from "../../images/search.png";
import PodcastList from "./podcast-list";
import { Fade } from "react-awesome-reveal";
class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
    this.onSearchChangeEventHandler =
      this.onSearchChangeEventHandler.bind(this);
  }

  onSearchChangeEventHandler(event) {
    this.setState(() => {
      return {
        search: event.target.value,
      };
    });
  }

  render() {
    return (
      <div className="podcast-main pb-[10rem]">
        <Fade direction="down" triggerOnce>
          <h1 className="text-white text-center sm:text-left">
            Explore Our Podcast!
          </h1>
          <div className="search-bar">
            <img src={SearchIcon} alt="search-icon" />
            <input
              type="text"
              placeholder="Search"
              value={this.state.search}
              onChange={this.onSearchChangeEventHandler}
            />
          </div>
        </Fade>
        <div className="podcast-list">
          <PodcastList search={this.state.search} />
        </div>
      </div>
    );
  }
}

export default Main;
