import pdf1 from "../highlight/ICCR.pdf";
import pdf2 from "../highlight/NETFLIX.pdf";
import pdf3 from "../highlight/Bubble.pdf";
import pdf4 from "../highlight/Stagflasi.pdf";

const pageHL = {
  title: "KSEP Highlight",
  subtitle:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras turpis quam, tincidunt id nibh vel, viverra feugiat arcu. Pellentesque eget ornare eros, et sagittis dui. Phasellus a urna et nulla suscipit sagittis sed sed sapien.",
  data: [
    {
      id: "4",
      title: "KSEP Highlight",
      subtitle: "Mengenal Fenomena Stagflasi",
      pdf: pdf4,
      image: "./image/highlight/Stagflasi.jpg",
      text: "Pada masa ini, perekonomian dunia kerap diguncang dengan berbagai fenomena yang merugikan kondisi perekonomian dunia. Salah satu fenomena yang kerap terjadi ialah Stagflasi. Sebenarnya apa itu Stagflasi? Apakah Stagflasi ini berdampak sangat besar untuk perekonomian dunia? Pada KSEP Highlight ini, kami dari tim RnCD akan membahas apa itu fenomena Stagflasi, hal - hal yang melatarbelakangi terjadinya fenomena ini dan berbagai hal menarik terkait fenomena ini Pantau terus LinkedIn kami serta akun Instagram @ksepitb untuk mendapatkan berita terkini seputar ekonomi dan pasar modal.",
    },
    {
      id: "3",
      title: "KSEP Highlight",
      subtitle: "Startup and Startup Bubble",
      pdf: pdf3,
      image: "./image/highlight/Bubble.jpg",
      text: "Pada masa ini banyak orang yang sering menyebut tentang startup. Banyak generasi muda yang bercita-cita untuk bekerja di perusahaan startup bergaji tinggi. Sebenarnya apa itu startup? Dan apa itu startup bubble yang dapat mengancam ribuan pegawai startup? Pada KSEP Highlight ini, Mesa Anugerah Kenan Sergio dan Yohanes Yordan Tjahjono akan membahas apa itu fenomena Startup Bubble, hal - hal yang melatarbelakangi tdan berbagai hal menarik terkait fenomena ini Pantau terus LinkedIn kami serta akun Instagram @ksepitb untuk mendapatkan berita terkini seputar ekonomi dan pasar modal.",
    },
    {
      id: "2",
      title: "KSEP Highlight",
      subtitle: "Turunnya Performa Netflix",
      pdf: pdf2,
      image: "./image/highlight/NETFLIX.jpg",
      text: "NETFLIX didirikan oleh Reed Hastings dan Marc Randolph di Scott Valleys, California. Bisnis awal NETFLIX bermula dari penyewaan DVD yang diprediksi akan menggantikan VHS Cassettes sebagai tontonan rumahan. NETFLIX berubah dari bisnis model penyewaan DVD dan Blu-Ray melalui pengiriman menjadi streaming unlimited access dengan biaya langganan yang bisa dibilang cukup terjangkau. Bisnis ini menjadi sukses dan meluas ke hampir seluruh dunia dengan pelanggan hingga 209 juta pada tahun 2021. Namun, NETFLIX mengalami goncangan pada kuartal pertama 2022 dan menjadi emiten dengan performa terburuk di antara emiten S&P 500 lainnya. Apa yang menyebabkan penurunan performa NETFLIX?. Pada KSEP Highlight ini, Henry Anand Septian Radityo dan Syaumi Indah Syafitri akan membahas faktor-faktor yang menyebabkan penurunan performa NETFLIX. Pantau terus LinkedIn kami serta akun Instagram @ksepitb untuk mendapatkan berita terkini seputar ekonomi dan pasar modal.",
    },
    {
      id: "1",
      title: "KSEP Highlight",
      subtitle: "Indonesia’s Capital City Relocation",
      pdf: pdf1,
      image: "./image/highlight/ICCR.jpg",
      text: "On August 26, 2019, President Joko Widodo announced plans to relocate Indonesia’s capital city from Jakarta to East Kalimantan, specifically in the District of Penajam Paser Utara and Kutai Kartanegara. In the midst of uncertainty due to Covid-19 pandemic, the government shows its commitment to undertake the development of this new capital city, known as IKN (Ibu Kota Negara) Nusantara. Which sector is benefiting the most from the capital city relocation?. In this edition of KSEP Highlight, Ardy Setia and Billy Christianto will try to analyze 4 sectors benefiting the most from Indonesia's capital city relocation. Stay tuned to our LinkedIn as well as Instagram page @ksepitb to get the latest economic and stock market news.",
    }
  ],
};

export default pageHL;
