import Logo from "../../images/Logo-Home.png";
import { Fade } from "react-awesome-reveal";

const Header = () => {
  return (
    <div className="header-home">
      <Fade direction="down" triggerOnce>
        <div className="header-container xl:flex-row flex-col-reverse xl:py-[6rem] py-[2rem] md:px-[6rem]">
          <div className="title-home xl:w-[60%] xl:text-[40px] text-[26px] xl:text-left text-center">
            <h1 className="font-bold">
              Kelompok Studi Ekonomi dan Pasar Modal
            </h1>
            <h1 className="mt-5">ITB</h1>
          </div>
          <div className="header-chart">
            <img src={Logo} alt="chart" />
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Header;
