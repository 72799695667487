import { NavLink } from "react-router-dom";
import LogoKSEP from "../../images/Logo KSEP.png";
import LogoTikTok from "../../images/logo-tiktok.png";
import LogoIG from "../../images/logo-instagram.png";
import LogoLinkedIn from "../../images/logo-linkedin.png";
import LogoTradingView from "../../images/logo-tradingview.png";
import LogoTradingViewOri from "../../images/logo-tradingview-ori.png";

const Footer = () => {
  const navObject = [
    {
      name: "Profile",
      path: "/profile",
    },
    {
      name: "Education",
      path: "/education",
    },
    {
      name: "Events",
      path: "/events",
    },
    {
      name: "Podcast",
      path: "/podcast",
    },
    {
      name: "Documentation",
      path: "/documentation",
    },
    {
      name: "Contact Us",
      path: "/contact-us",
    },
    {
      name: "Home",
      path: "/",
    },
  ];
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-title">
          <img src={LogoKSEP} alt="logo-ksep" />
          <span className="sm:text-[18px] text-[14px]">
            Kelompok Studi Ekonomi dan Pasar Modal
          </span>
        </div>
        <div className="footer-nav">
          <ul className="sm:block items-center flex-wrap m-auto mt-0 flex gap-y-4">
            <li className="grow w-1/4">
              <NavLink to={navObject[6].path} className="footer-nav-link">
                <span>{navObject[6].name}</span>
              </NavLink>
            </li>
            <li className="grow w-1/4">
              <NavLink to={navObject[0].path} className="footer-nav-link">
                <span>{navObject[0].name}</span>
              </NavLink>
            </li>
            <li className="grow w-1/4">
              <NavLink to={navObject[1].path} className="footer-nav-link">
                <span>{navObject[1].name}</span>
              </NavLink>
            </li>
            <li className="grow w-1/4">
              <NavLink to={navObject[2].path} className="footer-nav-link">
                <span>{navObject[2].name}</span>
              </NavLink>
            </li>
            <li className="grow w-1/4">
              <NavLink to={navObject[3].path} className="footer-nav-link">
                <span>{navObject[3].name}</span>
              </NavLink>
            </li>
            <li className="grow w-1/4">
              <NavLink to={navObject[4].path} className="footer-nav-link">
                <span>{navObject[4].name}</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <p className="px-4 sm:text-[18px] text-[14px]">
          Powered by TradingView
        </p>
        <div class="flex items-center justify-center pt-8">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://id.tradingview.com/"
          >
            <img
              className="w-20"
              src={LogoTradingViewOri}
              alt="logo-tradingview"
            />
          </a>
        </div>
        <p className="px-4 sm:text-[18px] text-[14px]">
          © 2022 Kelompok Studi Ekonomi dan Pasar Modal ITB. All Rights Reserved
        </p>
        <div className="logos">
          <div className="sidebox-left w-[30%] lg:w-[40%]"></div>
          <div className="logo-list sm:gap-[3rem] gap-[2rem]">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tiktok.com/@ksep_itb"
            >
              <img src={LogoTikTok} alt="logo-tiktok" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/ksepitb/"
            >
              <img src={LogoIG} alt="logo-instagram" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/ksepitb/"
            >
              <img src={LogoLinkedIn} alt="logo-linkedin" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.tradingview.com/u/ksepitb/"
            >
              <img
                className="w-10"
                src={LogoTradingView}
                alt="logo-tradingview"
              />
            </a>
          </div>
          <div className="sidebox-right w-[30%] lg:w-[40%]"></div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
