import NavBar from "../components/NavBar/index";
import {useEffect} from 'react';
import Header from "../components/Events/header";
import Main from "../components/Events/main";

const Event = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return (
        <div>
            <NavBar />
            <Header />
            <Main />     
        </div>
    )
}

export default Event;