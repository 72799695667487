import Organogram from "../../images/BP_RADIANT.png";
import Logo from "../../images/Logo-KSEP-Besar.png";
import { Fade } from "react-awesome-reveal";
const Main = () => {
  return (
    <div className="main-profile">
      <div className="bg-divisi z-[3]">
        <Fade direction="up">
          <img
            src={Logo}
            alt="logo-ksep"
            className="lg:absolute left-[-2rem] m-auto"
          />
        </Fade>
        <div className="py-10">
          <div className="flex flex-col gap-[1.5rem] py-[4rem] intro-right text-white w-[70%]">
            <Fade direction="right">
              <div className="lg:w-1/2 ml-auto linearbgright">
                <h2 className="text-5xl text-right pr-[4rem]">Our Vision</h2>
              </div>
              <p className="text-[24px] pr-[4rem] text-justify lg:text-right">
                KSEP ITB sebagai rumah pembelajaran dan pengembangan diri yang
                berdampak di bidang ekonomi dan pasar modal.
              </p>
            </Fade>
          </div>
          <div className="flex flex-col gap-[1.5rem] py-[4rem] w-[70%] text-white">
            <Fade direction="left">
              <div className="lg:w-1/2 linearbgleft">
                <h2 className="text-5xl pl-[4rem]">Our Mission</h2>
              </div>
              <ol
                type="number"
                className="list-decimal text-[24px] pl-[4rem] text-justify"
              >
                <li>
                  Menjadikan KSEP sebagai rumah yang nyaman bagi anggotanya
                </li>

                <li>
                  Memberikan dampak bagi masyarakat umum di dunia ekonomi dan
                  pasar modal
                </li>

                <li>
                  Menyediakan sarana bagi anggotanya untuk belajar dan
                  berkembang khususnya di bidang ekonomi dan pasar modal
                </li>
              </ol>
            </Fade>
          </div>
        </div>
      </div>
      <div className="organogram relative overflow-hidden">
        <Fade triggerOnce>
          <div className="absolute w-[500px] h-[500px] right-[-124px] bg-red-linear rounded-[50%] top-[-20%] overflow-hidden lg:block hidden"></div>
        </Fade>
        <Fade direction="down">
          <img src={Organogram} alt="orgranogram" className="m-auto z-[2] rounded-xl" />
        </Fade>
        <Fade triggerOnce>
          <div className="absolute w-[500px] h-[500px] left-[-124px] bg-red-linear rounded-[50%] top-[70%] overflow-hidden z-[-1] lg:block hidden"></div>
        </Fade>
      </div>
    </div>
  );
};

export default Main;
