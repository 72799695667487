import { useParams } from "react-router-dom";
import NavBar from "../NavBar";
import { Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { Fade } from "react-awesome-reveal";

const EducationProduct = (props) => {
  const { id } = useParams();
  const data = props.data.data.filter((item) => item.id === id)[0];

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <>
      <NavBar />
      <div className="pdf-container xl:w-1/2 mx-auto mb-[55rem] md:mb-[38rem]">
        <Fade direction="down">
          <h1>{data.title}</h1>
        </Fade>
        <Worker
          workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js"
          className="w-1"
        >
          <Viewer
            className="w-1"
            fileUrl={data.pdf}
            plugins={[defaultLayoutPluginInstance]}
          />
        </Worker>
        <Fade direction="down">
          <p className="w-4/5 xl:w-full m-auto">{data.text}</p>
        </Fade>
      </div>
    </>
  );
};

export default EducationProduct;
