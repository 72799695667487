import React from "react";
import MainEducation1 from "./Content/index";
import MainEducation2 from "./Content/index2";
import pageHL from "./Content/highlight";
import pageMR from "./Content/marketRecap";
import Header from "./Content/header";
import { Fade } from "react-awesome-reveal";
class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page1: true,
      page2: false,
    };
    this.onPage1ChangeEventHandler = this.onPage1ChangeEventHandler.bind(this);
    this.onPage2ChangeEventHandler = this.onPage2ChangeEventHandler.bind(this);
  }

  onPage1ChangeEventHandler() {
    this.setState(() => {
      return {
        page1: true,
        page2: false,
      };
    });
  }

  onPage2ChangeEventHandler() {
    this.setState(() => {
      return {
        page1: false,
        page2: true,
      };
    });
  }

  render() {
    if (this.state.page1) {
      return (
        <div className="education-container">
          <Fade direction="down" triggerOnce>
            <Header
              button1={this.onPage1ChangeEventHandler}
              button2={this.onPage2ChangeEventHandler}
              page={this.state.page1}
            />
          </Fade>
          <MainEducation2 pagedata={pageHL} />
        </div>
      );
    } else {
      return (
        <div className="education-container">
          <Fade direction="down" triggerOnce>
            <Header
              button1={this.onPage1ChangeEventHandler}
              button2={this.onPage2ChangeEventHandler}
              page={this.state.page1}
            />
          </Fade>
          <MainEducation1 pagedata={pageMR} />
        </div>
      );
    }
  }
}

export default Main;
