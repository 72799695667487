import Intro from "../Home/intro";
import Podcast from "../../images/podcast-home.png";
import Spotify from "../../images/spotify-code.png";
import Highlight from "../../images/Highlight.png";
import SlideShow from "./slideshow";
import Button from "./button";
import Welcome from "../../images/welcome.png";
import { Fade } from "react-awesome-reveal";

const Main = () => {
  const content = [
    {
      title: "Welcome to KSEP !",
      content:
        "KSEP ITB is a campus-based student-led organisation found in 22 November 1997 that aimed to be the leading platform to develop economics & stock market knowledge in ITB.",
      nav: "/profile",
    },
    {
      title: "Education",
      content:
        "In this organisation we regularly hold trainings about economics and capital market, as well as soft skill trainings, and sharing educational content through various media",
      nav: "/education",
    },
    {
      title: "Our achievement",
      content:
        "Currently, KSEP ITB has over 200 active members from all faculties in ITB, and each year we recruit a new batch of members in the Open House Unit festival at ITB.",
      nav: "/documentation",
    },
  ];
  return (
    <div className="main-home">
      <div>
        <div className="w-[90%] m-auto xl:p-[6rem] flex xl:gap-20 items-center z-[-1] xl:flex-row flex-col">
          <Fade triggerOnce>
            <div className="absolute w-[500px] h-[500px] left-[-124px] bg-blue-linear rounded-[50%] top-[160%] overflow-hidden xl:block hidden"></div>
          </Fade>
          <Fade direction="down" triggerOnce>
            <div className="py-10">
              <img
                src={Welcome}
                alt="KSEPodcast Cover"
                className="rounded-[50%] w-[100rem] z-[2] relative"
              />
            </div>
          </Fade>
          <div className="flex flex-col gap-4 items-center xl:items-start">
            <Fade direction="down" triggerOnce>
              <h3 className="text-[50px] sm:text-[76px] text-[#C81D39] xl:text-left text-center">
                Welcome to <br></br>KSEP
              </h3>
              <p className="text-[#00405A] text-justify leading-9 text-[20px] mb-5">
                KSEP ITB is a campus-based student-led organisation found in 22
                November 1997 that aimed to be the leading platform to develop
                economics & stock market knowledge in ITB.
              </p>
              <Button nav="/profile" />
            </Fade>
            <Fade triggerOnce>
              <div className="absolute w-[100px] h-[100px] left-[40%] bg-blue-linear rounded-[50%] top-[125%] z-[2] xl:block hidden"></div>
            </Fade>
          </div>
        </div>
      </div>
      <Fade direction="down" triggerOnce>
        <div className="py-20">
          <iframe
            src="https://www.youtube.com/embed/rXyDIOXzAeA"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="mx-auto sm:w-[560px] sm:h-[315px] w-[348px] h-[196px]"
          ></iframe>
        </div>
      </Fade>
      <Fade direction="down" triggerOnce>
        <div className="event-home bg-[#C81D39] py-10 relative">
          <h2 className="text-6xl">Our Events</h2>
          <SlideShow />
        </div>
      </Fade>
      <div className="relative overflow-hidden">
        <Fade triggerOnce>
          <div className="absolute w-[500px] h-[500px] right-[-124px] bg-blue-linear rounded-[50%] top-[-20%] overflow-hidden"></div>
        </Fade>
        <Fade direction="down" triggerOnce>
          <div className="w-2/3 md:ml-24 mx-auto">
            <Intro
              title={content[1].title}
              content={content[1].content}
              nav={content[1].nav}
              align="left"
            />
          </div>
        </Fade>
        <Fade direction="down" triggerOnce>
          <div className="container-card md:flex-row flex-col">
            <div className="card-home bg-card-red md:w-[40%]">
              <a href="/podcast" className="flex flex-col items-center">
                <h3>
                  Listen to <span>Our Podcast</span>
                </h3>
                <img src={Podcast} alt="podcast" id="podcast" />
                <img src={Spotify} alt="spotify" id="spotify" />
              </a>
            </div>
            <div className="card-home bg-card-blue md:w-[40%]">
              <a href="/education" className="flex flex-col items-center">
                <h3>
                  Check Our <span>Highlight Recap</span>
                </h3>
                <img src={Highlight} alt="highlight" />
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Main;
