import Divisi from "./divisi";
import DataEvent from "./data";

const Main = () => {
  return (
    <div className="event-main">
      {DataEvent.map((item, idx) => (
        <Divisi divisi={item.divisi} konten={item.konten} id={idx} />
      ))}
    </div>
  );
};

export default Main;
