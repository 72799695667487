import NavBar from "../components/NavBar/index";
import Main from "../components/Documentation/index"
import {useEffect} from 'react';

const Documentation = () => {
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    return (
        <div>
            <NavBar />
            <Main />
        </div>
    )
}

export default Documentation;