import KSEPodcast from "../../images/KSEPodcast-cover.jpg";
import Button from "./button";
import { Fade } from "react-awesome-reveal";

const Header = () => {
  return (
    <div className="w-full xl:w-4/5 m-auto p-[4rem] sm:p-[6rem] flex md:gap-20 items-center md:flex-row flex-col">
      <Fade direction="down" triggerOnce>
        <div>
          <img
            src={KSEPodcast}
            alt="KSEPodcast Cover"
            className="rounded-2xl"
          />
        </div>
      </Fade>
      <Fade direction="down" triggerOnce>
        <div className="flex flex-col gap-4 mt-5 md:mt-0">
          <h3 className="text-[36px] md:text-[50px] text-white text-center md:text-left">
            About <br></br>KSEP Podcast
          </h3>
          <p className="text-white text-justify leading-9">
            "KSEPodcast adalah sebuah podcast dari Kelompok Studi Ekonomi dan
            Pasar Modal Institut Teknologi Bandung (KSEP ITB) yang mengajak
            kalian untuk membahas seputar ekonomi, pasar modal, serta
            pengembangan diri secara menarik dan informatif. Ikuti terus podcast
            kami untuk mengisi waktu kalian dengan belajar bersama kami. Selamat
            mendengarkan! Enjoy, dan #SalamCuan!"
          </p>
          <Button />
        </div>
      </Fade>
    </div>
  );
};

export default Header;
