import Pagination from "./pagination";
import Card from "./card";
import { Fade } from "react-awesome-reveal";

const Main = ({ pagedata, proceed, id }) => {
  return (
    <div className="content-container relative z-[3]">
      <Fade direction="down">
        <Pagination
          data={pagedata}
          RenderComponent={Card}
          title="Posts"
          pageLimit={1}
          dataLimit={6}
        />
      </Fade>
    </div>
  );
};

export default Main;
